import * as React from "react"
import CaseStudyTemplate from "../components/case-study-template";
import { StaticImage } from "gatsby-plugin-image";

const content = {
	title: `Coopers`,
	heading: 'Australian Open Promotion',
	emphasisedText: 'Open Australian to win box seats to the Australian Open',
	text: [
		'Coopers Brewery took centre court again as the Official Beer Partner of the Australian Open, giving tennis-goers the chance to enjoy an Australian beer at the Australian Open.',
		'Coopers engaged KO via Premium Beverages to develop a basic single promotion giving away box seats to this quintessential Aussie event. The promo featured standard functionality including receipt uploader in the front end, and a randomised prize draw system in the back end.'
	],
	numbers: {
		Entrants: '3000',
		Winners: '500',
		'In prizes': '$1M',
	},
};

export default function Coopers() {
	return (
		<CaseStudyTemplate 
			heading={content.heading}
			superHeading={content.title}
			image={<StaticImage alt="Hero image" src="../images/Coopers@3x.png" width={500} height={500} />}
			content={{
				title: content.emphasisedText,
				text: content.text,
			}}
			numbers={content.numbers}
		/>
	)
}
